import React from 'react'
import Hero from '../../components/Hero/Hero'
import Services from '../../components/Services/Services'
import Categories from '../../components/Categories/Categories'
import AboutUs from '../../components/AboutUs/AboutUs'
import OurClients from '../../components/OurClients/OurClients'

const Home = () => {

  return (
    <div>
      <Hero />
      <Services />
      <AboutUs />
      <Categories />
      <OurClients />
    </div>
  )
}

export default Home
