import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import hymod from '../../assets/coworking/hymod.png';
import hymod_copy from '../../assets/coworking/hymod - Copy.png';
import bclap from '../../assets/coworking/bclap.webp';
import bclap_copy from '../../assets/coworking/bclap - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const Coworking = () => {
  const [images, setImages] = useState({
    hymodImg: hymod_copy,
    bclapImg: bclap_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages((prevImages) => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: hymod, key: 'hymodImg' },
      { src: bclap, key: 'bclapImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const coworkingSpaces = [
    { name: "Hymod Workspace", img: images.hymodImg, url: "https://cybozom.in/360/3d/hymod/" },
    { name: "Bclap Mini Coneference Hall", img: images.bclapImg, url: "https://cybozom.site/360/bclap1/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>COWORKING</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {coworkingSpaces.map((space, index) => (
          <button key={index} onClick={() => navigateToExternalLink(space.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={space.name} img={space.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Coworking;
