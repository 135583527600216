import React from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {
  return (
    <div className='rounded-xl'>
      <Link to={props.link} >
        <div className='group relative rounded-xl bg-[#FCEAE8] p-1 sm:p-5 m-auto transition-all duration-100 hover:border-8 border-transparent flex flex-col items-center justify-between h-full'>
          <img src={props.src} className='w-[50%] h-auto mt-10 m-auto text-[#293461] transition-colors duration-100 group-hover:text-[#C91D4F]' loading='lazy'/>
          <h1 className='mt-5 mb-5 text-lg sm:text-xl font-semibold sm:font-bold text-center text-[#293461] transition-colors duration-100 group-hover:text-[#C91D4F]'>{props.name}</h1>
          <div className='absolute inset-0 rounded border-8 border-transparent group-hover:border-[#C91D4F] transition-all duration-100'></div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
