import React from 'react'

const ServiceCard = (props) => {
  return (
    <div className='md:flex gap-7 sm:gap-5 md:gap-10 rounded-md p-4 h-full '>
      <div className='flex-shrink-0 place-content-center'>
        <img src={props.img} alt={props.name} className='w-16  min-[400px]:h-16 object-contain m-auto'/>
      </div>
      <div className='flex flex-col justify-between'>
        <div>
          <h1 className='text-lg sm:text-2xl text-center md:text-start mt-5 font-semibold mb-2 md:mb-5'>{props.name}</h1>
          <p className='text-justify'>{props.text}</p>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
