import React from 'react'
import InfiniteCarousel from '../../layouts/InfiniteCarousel/InfiniteCarousel'

const OurClients = () => {

  return (
    <div className='mt-20 sm:mt-32 overflow-hidden'>
      <h1 className='text-center text-xl font-semibold'>Happy Clients</h1>
      <hr className='border-[#C91D4F] w-10 border-2 m-auto mt-1 rounded-full'/>
        {/* <h1 className='text-center text-2xl min-[450px]:text-3xl sm:text-4xl font-semibold mb-2 sm:mb-16 mt-5 sm:mt-10 text-[#C91D4F]'>Happy Clients</h1> */}
        <div className='mx-5 sm:mx-20'>
        <InfiniteCarousel />
        </div>
    </div>
  )
}

export default OurClients
