import React, { useEffect } from 'react'
import CategoriesCard from '../../layouts/CategoriesCard/CategoriesCard'
import { Link } from 'react-router-dom'
import Aos from 'aos';

const Categories = () => {

  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);

  return (
    <div>
      <div className='w-fit m-auto mt-20 sm:mt-32 '>
        <h1 className='text-center text-xl font-semibold'>Top Categories</h1>
        <hr className='border-[#C91D4F] w-10 border-2 m-auto mt-2 rounded-full'/>
        <h1 className='text-center text-2xl min-[450px]:text-3xl sm:text-4xl font-semibold mt-5 sm:mt-10 text-[#C91D4F] '>Explore Categories</h1>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 mt-10  mx-5 min-[395px]:mx-10 md:mx-20 mb-10 gap-10'>
        <Link data-aos="fade-right" className='overflow-hidden rounded-xl ' to='https://www.google.com/maps/@11.2453339,75.8366771,3a,75y,125.6h,90.06t/data=!3m8!1e1!3m6!1sAF1QipMhag3kk99D3dX8QRiE7IWhKSAsl-XH9uF_cBbD!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMhag3kk99D3dX8QRiE7IWhKSAsl-XH9uF_cBbD%3Dw900-h600-k-no-pi-0.060000000000002274-ya93.97324905395507-ro0-fo90!7i8000!8i4000?coh=205410&entry=ttu'>
          <CategoriesCard title="Google Street View" num="01"  />
        </Link>
        <Link data-aos="fade-right" className='overflow-hidden rounded-xl ' to='https://cybozom.site/360/nilgiricollege/'>
          <CategoriesCard title="2D Virtual Tour" num="02" />
        </Link>
        <Link data-aos="fade-right" className='overflow-hidden rounded-xl ' to='https://cybozom.in/360/3d/hymod/'>
          <CategoriesCard title="3D Virtual Tour" num="03" />
        </Link>
        <Link data-aos="fade-right" className='overflow-hidden rounded-xl ' to='https://cybozom.in/360/virtualhelpdesk/skyford/'>
          <CategoriesCard title="Virtual Helpdesk" num="04"  />
        </Link>
        {/* <Link data-aos="fade-right" className='overflow-hidden rounded-xl ' to='/about'><CategoriesCard title="Virtual Expo" num="05"  /></Link> */}
      </div>
    </div>
  )
}

export default Categories
