import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import expo_2023 from '../../assets/virtual expo/expo 2023.webp';
import expo_2023_copy from '../../assets/virtual expo/expo 2023 - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const VirtualTour = () => {
  const [images, setImages] = useState({
    expo23Img: expo_2023_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages((prevImages) => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: expo_2023, key: 'expo23Img' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const tours = [
    { name: "Expo 2023", img: images.expo23Img, url: "https://cybozom.site/360/expo2023/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>3D VIRTUAL TOUR</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {tours.map((tour, index) => (
          <button key={index} onClick={() => navigateToExternalLink(tour.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={tour.name} img={tour.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default VirtualTour;