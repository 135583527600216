import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import y3_suites from '../../assets/resort/y3 suites.png';
import y3_suites_copy from '../../assets/resort/y3 suites - Copy.png';
import misty_queen from '../../assets/resort/misty queen.webp';
import misty_queen_copy from '../../assets/resort/misty queen - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const Resort = () => {
  const [images, setImages] = useState({
    y3SuitesImg: y3_suites_copy,
    mistyQueenImg: misty_queen_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages(prevImages => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: y3_suites, key: 'y3SuitesImg' },
      { src: misty_queen, key: 'mistyQueenImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const resorts = [
    { name: "Misty Queen", img: images.mistyQueenImg, url: "https://www.thedimorahotels.com/misty-queen-dimora-kakkadampoyil/virtual-tour/" },
    { name: "Y3 Suites", img: images.y3SuitesImg, url: "https://cybozom.in/360/resort/y3suites/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>RESORT</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {resorts.map((resort, index) => (
          <button key={index} onClick={() => navigateToExternalLink(resort.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={resort.name} img={resort.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Resort;
