import React, { useEffect } from 'react';
import Card from '../../layouts/Card';
import hotel from '../../assets/categories/hotel.webp';
import hospital from '../../assets/categories/hospital.webp';
import gym from '../../assets/categories/gym.webp';
import school from '../../assets/categories/school.webp';
import college from '../../assets/categories/college.webp';
import resort from '../../assets/categories/resort.webp';
import auditorium from '../../assets/categories/auditorium.webp';
import coworking from '../../assets/categories/coworking.webp';
import virtual_helpdesk from '../../assets/categories/virtual helpdesk.webp';
import virtual_tour from '../../assets/categories/3d virtual tour.webp';
import streetview from '../../assets/categories/streetview.webp';
import virtual_expo from '../../assets/categories/virtual expo.webp';
import { FaPhone } from "react-icons/fa";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import dp from '../../assets/dp.webp';

const Portfolio = () => {

  const categoriesData = [
    { name: "Hotel", src: hotel, link: "/hotel" },
    { name: "School", src: school, link: "/school" }, 
    { name: "College", src: college, link: "/college" },
    { name: "Resort", src: resort, link: "/resort" },
    { name: "Hospital", src: hospital, link: "/hospital" },
    { name: "Gym", src: gym, link: "/gym" },
    { name: "Auditorium", src: auditorium, link: "/auditorium" },
    { name: "Coworking", src: coworking, link: "/coworking" },
    { name: "Virtual Helpdesk", src: virtual_helpdesk, link: "/virtual-helpdesk" },
    { name: "3D Virtual Tour", src: virtual_tour, link: "/virtual-tour" },
    { name: "Google Street View", src: streetview, link: "/google-streetview" },
    { name: "Virtual Expo", src: virtual_expo, link: "/virtual-expo" },
  ];

  const makePhoneCall = () => {
    window.location.href = "tel:+918590506573";
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className='mt-20 sm:mt-32 mb-0 sm:mb-0'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-center text-[#293461]'>CATEGORIES</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 justify-around gap-3 sm:gap-5 mx-5 lg:mx-20 relative'>
        {categoriesData.map((category, index) => (
          <Card key={index} name={category.name} src={category.src} link={category.link} />
        ))}
      </div>

      <button 
        onClick={makePhoneCall} 
        className='fixed bottom-8 left-5 z-30 bg-[#293461] p-4 rounded-full shadow-gray-900 shadow transition-transform duration-500 ease-in-out transform hover:scale-110'
      >
        <FaPhone className='w-7 h-7 text-white transition-transform duration-500 ease-in-out transform hover:-rotate-45' />
      </button>
      <FloatingWhatsApp phoneNumber="+918590506573" accountName="Cybozom 360" avatar={dp} statusMessage='' notificationDelay='30' className='z-30'/>
    </div>
  );
};

export default Portfolio;
