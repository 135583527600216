import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import cruxlife from '../../assets/gym/cruxlife.png';
import cruxlife_copy from '../../assets/gym/cruxlife - Copy.png';
import fitnessland from '../../assets/gym/fitness land.png';
import fitnessland_copy from '../../assets/gym/fitness land - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const Gym = () => {
  const [images, setImages] = useState({
    cruxlifeImg: cruxlife_copy,
    fitnesslandImg: fitnessland_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages((prevImages) => ({ ...prevImages, [key]: src }));
      };
    };

    loadImage(cruxlife, 'cruxlifeImg');
    loadImage(fitnessland, 'fitnesslandImg');
  }, []);

  const gyms = [
    { name: "Cruxlife", img: images.cruxlifeImg, url: "https://cybozom.in/360/gym/cruxlife/" },
    { name: "Fitness Land - The Family Fitness Centre", img: images.fitnesslandImg, url: "https://cybozom.in/360/gym/fitnessland/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>GYM</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {gyms.map((gym, index) => (
          <button key={index} onClick={() => navigateToExternalLink(gym.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={gym.name} img={gym.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Gym;
