import React from 'react'
import img1 from '../../assets/clients/fezin_logo.png'
import img2 from '../../assets/clients/jdt_logo.png'
import img3 from '../../assets/clients/gokulam_logo.png'
import img4 from '../../assets/clients/markaz_logo.png'
import img5 from '../../assets/clients/dr-chandrakanth-logo.avif'
import img6 from '../../assets/clients/marina_logo.png'
import img7 from '../../assets/clients/paramount_logo.jpg'
import img8 from '../../assets/clients/east_evenue_logo.png'
import img9 from '../../assets/clients/niligiri_logo.png'
import img10 from '../../assets/clients/y3suites_logo.png'
import img11 from '../../assets/clients/global_logo.jpg'
import img12 from '../../assets/clients/excel_logo.png'
import img13 from '../../assets/clients/casino_logo.png'
import img14 from '../../assets/clients/cruxlife-logo.png'
import img15 from '../../assets/clients/Fitnessland-logo.png'
import img16 from '../../assets/clients/hymod_logo.png'
import img17 from '../../assets/clients/skyford_logo.png'
import img18 from '../../assets/clients/cardea_logo.png'
import img19 from '../../assets/clients/omer_logo.png'
import img20 from '../../assets/clients/Philips_logo.webp'
import img21 from '../../assets/clients/sadhbhavana-logo.png'


const InfiniteCarousal = () => {

    const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21];

  return (
    <div class="mt-10 w-full inline-flex flex-nowrap overflow-hidden bg-gray-100 p-5 rounded-xl">
        <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
            {images.map((img, index) => (
                <li key={index} className="inline-block">
                <img src={img} alt={`Brand ${index + 1}`} className="h-16 w-16 sm:h-40 sm:w-40 object-contain" />
                </li>
            ))}
        </ul>
        <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
            {images.map((img, index) => (
                <li key={index} className="inline-block">
                <img src={img} alt={`Brand ${index + 1}`} className="h-10 w-10 sm:h-40 sm:w-40 object-contain" />
                </li>
            ))}
        </ul>
    </div>
  )
}

export default InfiniteCarousal