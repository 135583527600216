import React, { useEffect } from 'react'
import ContactForm from '../../components/ContactForm/ContactForm'
import { FaFacebook, FaLinkedin, FaPinterest } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { FaSquareInstagram } from 'react-icons/fa6'


const Contact = () => {

  const openChat = () => {
    window.location.href = "https://wa.me/+918590506573"
  }

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  return (
    <div className='mt-36'>
      <h1 className='text-center text-2xl min-[450px]:text-3xl sm:text-4xl font-semibold mt-5 sm:mt-10 '>Get In Touch</h1>
      <h2 className='text-center mt-5 text-sm sm:text-base'>Fill in the form or if you prefer,  
            <Link to='mailto:cybozom@gmail.com'><span className='text-[#C91D4F] hover:italic'>  send us an email</span></Link>
      </h2>
      <ContactForm />
      <div className='grid grid-cols-1 md:grid-cols-2 gap-10 mx-5 sm:mx-20 mt-10'>
        <div className='py-2 px-5 sm:px-10'>
            <h1 className='text-lg font-semibold mb-5'>Contact Us</h1>
            <p className='mb-2'>Phone: +91 8590 506 573</p>
            <p className='mb-2'>Email: cybozom2.0@gmail.com</p>
            <p>Room 1302, 3rd floor, Hilite Business Park, Kozhikode Hilite City, National Highway 66 Bypass, Thondayad, Pantheeramkavu, Kozhikode, Kerala 673014</p>
            <div className='flex gap-3 mt-7 sm:mt-10 '>
              <Link onClick={openChat}><IoLogoWhatsapp className='w-7 h-7 text-green-600'/></Link>
              <Link to='https://www.facebook.com/cybozom360?mibextid=ZbWKwL'><FaFacebook className='h-7 w-7 text-blue-600'/></Link>
              <Link to='https://instagram.com/cybozom360?igshid=YmMyMTA2M2Y'><FaSquareInstagram className='h-7 w-7 text-pink-600'/></Link>
              <Link to='https://www.linkedin.com/in/cybozom/'><FaLinkedin className='w-7 h-7 text-blue-600'/></Link>
              {/* <Link to='https://x.com/'><FaSquareXTwitter className='w-7 h-7 '/></Link> */}
              <Link to='https://in.pinterest.com/cybozom/'><FaPinterest className='w-7 h-7 text-red-600'/></Link>
            </div>
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d978.2960750636023!2d75.83327006950155!3d11.247848914654273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6597c304c1f3b%3A0xbb7abc23c1ea8a!2sHiLITE%20Business%20Park!5e0!3m2!1sen!2sin!4v1721805077270!5m2!1sen!2sin"
            style={{ border: "0", borderRadius: "10px" }}
            className='w-full rounded-xl h-80'
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
    </div>
  )
}

export default Contact