import React, { useState } from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import './Navbar.css'
import logo from '../../assets/Cybozom_logo.webp'
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoCloseOutline } from 'react-icons/io5';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import { IoIosMail } from "react-icons/io";


const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const openMail = () => {
        window.location.href = "mailto:cybozom2.0@gmail.com"
    }
    
    const isPortfolioActive = location.pathname.startsWith('/portfolio') || [
        '/hotel',
        '/school',
        '/college',
        '/resort',
        '/hospital',
        '/gym',
        '/auditorium',
        '/coworking',
        '/virtual-helpdesk',
        '/virtual-tour',
        '/google-streetview'
    ].includes(location.pathname);

  return (
    <>
        <div className={` px-3 lg:px-5 sm:py-2 navbar fixed top-0 z-30 w-full `}>
            <div className="flex justify-between items-center">
                <Link to='/'><img src={logo} alt="Cybozom Logo" className="w-32 h-auto" /></Link>
                <nav className="hidden md:flex gap-x-3 lg:gap-10 list-none ">
                <NavLink to='/' className="md:mx-2 link-with-dot-border"><li>Home</li></NavLink>
                <NavLink to="/portfolio" className={`md:mx-2 link-with-dot-border ${isPortfolioActive ? 'active' : ''}`}><li>Portfolio</li></NavLink>
                <NavLink to='/about' className="md:mx-2  link-with-dot-border"><li>About</li></NavLink>
                <NavLink to='/contact' className="md:mx-2  link-with-dot-border"><li>Contact</li></NavLink>
                </nav>
                <div className='hidden md:flex gap-5 items-center'>
                    <div className=' bg-black hover:bg-transparent border hover:text-black hover:border-black transition duration-200 text-white py-2 px-3 text-sm flex gap-1 items-center rounded-xl cursor-pointer' onClick={openMail}><IoIosMail /> Connect</div>
                </div>
                <button
                className="md:hidden text-gray-500 focus:outline-none"
                onClick={toggleMenu}
                >
                {isOpen ? (
                    <IoCloseOutline onClick={closeMenu} className='h-10 w-10 fixed top-2 right-3 fade z-50 text-[#293461] hover:text-[#C91D4F]' />
                ) : (
                    <HiOutlineMenuAlt4 className='h-8 w-8 text-[#293461] hover:text-[#C91D4F] ' />
                )}
                </button>
            </div>

            {isOpen && (
                <div className={`fixed -top-1 inset-0 z-40 bg-[#F8F8F8] h-screen w-full menu ${isOpen ? 'slide-right' : 'slide-left'}`}>
                    <Menu closeMenu = {closeMenu}/>
                </div>
            )}
        </div>
        <Outlet />
        <Footer />
    </>
  )
}

export default Navbar
