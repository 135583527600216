import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import markaz from '../../assets/helpdesk/markaz.png';
import markaz_copy from '../../assets/helpdesk/markaz - Copy.png';
import cardea from '../../assets/helpdesk/cardea.png';
import cardea_copy from '../../assets/helpdesk/cardea - Copy.png';
import omar_academy from '../../assets/helpdesk/omar academy.png';
import omar_academy_copy from '../../assets/helpdesk/omar academy - Copy.png';
import skyford from '../../assets/helpdesk/skyford.png';
import skyford_copy from '../../assets/helpdesk/skyford - Copy.png';
import markaz_karanthur from '../../assets/helpdesk/markaz karanthur.png';
import markaz_karanthur_copy from '../../assets/helpdesk/markaz karanthur - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const VirtualHelpdesk = () => {
  const [images, setImages] = useState({
    markazImg: markaz_copy,
    cardeaImg: cardea_copy,
    omarAcademyImg: omar_academy_copy,
    skyfordImg: skyford_copy,
    markazKaranthurImg: markaz_karanthur_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages((prevImages) => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: markaz, key: 'markazImg' },
      { src: cardea, key: 'cardeaImg' },
      { src: omar_academy, key: 'omarAcademyImg' },
      { src: skyford, key: 'skyfordImg' },
      { src: markaz_karanthur, key: 'markazKaranthurImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const helpdesks = [
    { name: "Markaz Helpdesk", img: images.markazImg, url: "https://cybozom.in/360/virtualhelpdesk/markaz/" },
    { name: "Cardea Helpdesk", img: images.cardeaImg, url: "https://cybozom.in/360/virtualhelpdesk/cardea/" },
    { name: "Omar Academy Helpdesk", img: images.omarAcademyImg, url: "https://cybozom.in/360/virtualhelpdesk/omar/" },
    { name: "Skyford Helpdesk", img: images.skyfordImg, url: "https://cybozom.in/360/virtualhelpdesk/skyford" },
    { name: "Markaz Karanthur Helpdesk", img: images.markazKaranthurImg, url: "https://cybozom.in/360/virtualhelpdesk/markazkaranthur/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>VIRTUAL HELPDESK</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {helpdesks.map((helpdesk, index) => (
          <button key={index} onClick={() => navigateToExternalLink(helpdesk.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={helpdesk.name} img={helpdesk.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default VirtualHelpdesk;
