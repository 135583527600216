import React from 'react';
import { FaFacebook, FaLinkedin, FaPinterest } from 'react-icons/fa';
import { FaSquareInstagram, FaSquareXTwitter } from 'react-icons/fa6';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import logo from '../../assets/Cybozom_logo.webp'


const Menu = ({closeMenu}) => {

  const openMail = () => {
    window.location.href = "mailto:cybozom2.0@gmail.com"
  }

  const openChat = () => {
    window.location.href = "https://wa.me/+918590506573"
  }

  return (
    <>
      <div className='mt-1 ms-3'>
        <Link to='/'><img src={logo} alt="Cybozom Logo" className="w-32 h-auto" /></Link>
      </div>
      <ul className="list-none mt-20 text-xl mx-5">
        <Link onClick={closeMenu} to='/' className="md:mx-2 link-with-dot-border ">
          <div className='mb-5 flex justify-between items-center '>
            <li className=''>Home</li>
            <IoIosArrowForward className='w-7 h-7'/>
          </div>
        </Link>
        <Link onClick={closeMenu} to='/portfolio' className="md:mx-2 link-with-dot-border ">
          <div className='mb-5 flex justify-between items-center '>
            <li className=''>Portfolio</li>
            <IoIosArrowForward className='w-7 h-7'/>
          </div>
        </Link>
        <Link onClick={closeMenu} to='/about' className="md:mx-2 link-with-dot-border ">
          <div className='mb-5 flex justify-between items-center '>
            <li className=''>About</li>
            <IoIosArrowForward className='w-7 h-7'/>
          </div>
        </Link>
        <Link onClick={closeMenu} to='/contact' className="md:mx-2 link-with-dot-border ">
          <div className='mb-5 flex justify-between items-center '>
            <li className=''>Contact</li>
            <IoIosArrowForward className='w-7 h-7'/>
          </div>
        </Link>
      </ul>

      <div className='w-[90%] m-auto place-content-center bg-black hover:bg-transparent border hover:text-black hover:border-black transition duration-200 text-white py-3 text-xl mt-20 px-5 flex gap-2 items-center rounded-xl cursor-pointer' onClick={openMail}>
        <IoIosMail /> Connect
      </div>

      <div className='flex gap-5 justify-center items-center mt-60'>
        <Link onClick={openChat}><IoLogoWhatsapp className='w-7 h-7 hover:scale-110'/></Link>
        <Link to='https://www.facebook.com/cybozom360?mibextid=ZbWKwL'><FaFacebook className='h-7 w-7 hover:scale-110'/></Link>
        <Link to='https://instagram.com/cybozom360?igshid=YmMyMTA2M2Y'><FaSquareInstagram className='h-7 w-7 hover:scale-110'/></Link>
        <Link to='https://www.linkedin.com/in/cybozom/'><FaLinkedin className='w-7 h-7 hover:scale-110'/></Link>
        <Link to='https://in.pinterest.com/cybozom/'><FaPinterest className='w-7 h-7 hover:scale-110'/></Link>
      </div>
    </>
  );
};

export default Menu;
