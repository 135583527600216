import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import hymod from '../../assets/3d virtual tour/hymod.png';
import hymod_copy from '../../assets/3d virtual tour/hymod - Copy.png';
import philips from '../../assets/3d virtual tour/philips.png';
import philips_copy from '../../assets/3d virtual tour/philips - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const VirtualTour = () => {
  const [images, setImages] = useState({
    hymodImg: hymod_copy,
    philipsImg: philips_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages((prevImages) => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: hymod, key: 'hymodImg' },
      { src: philips, key: 'philipsImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const tours = [
    { name: "Hymod Workspace", img: images.hymodImg, url: "https://cybozom.in/360/3d/hymod/" },
    { name: "Philips Smart Light Hub", img: images.philipsImg, url: "https://cybozom.in/360/3d/philips/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>3D VIRTUAL TOUR</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {tours.map((tour, index) => (
          <button key={index} onClick={() => navigateToExternalLink(tour.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={tour.name} img={tour.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default VirtualTour;
