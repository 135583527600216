import React from 'react';

const AboutCard = (props) => {
  return (
    <div className=" bg-base-100 shadow mx-auto py-4 rounded-xl"> 
      <figure className="px-10 pt-10">
        <img src={props.img} alt={props.title} className="rounded-xl m-auto w-60 h-40" />
      </figure>
      <div className="px-5 text-center"> 
        <h2 className="text-xl font-semibold mt-5 text-[#C91D4F]">{props.title}</h2>
        <p className="text-justify break-words my-5"> 
          {props.text}
        </p>
      </div>
    </div>
  );
}

export default AboutCard;
