import React, { Suspense, lazy } from 'react';
// import img from '../../assets/About_img.webp'

const VideoComponent = lazy(() => import('../../layouts/VideoComponent/VideoComponent'));

const AboutUs = () => {
  return (
    <div className='overflow-hidden'>
      <div className='w-fit m-auto mt-20 sm:mt-32 '>
        <h1 className='text-center text-xl font-semibold'>What We Do</h1>
        <hr className='border-[#C91D4F] w-10 border-2 m-auto mt-1 rounded-full'/>
        <h1 className='text-center text-2xl min-[450px]:text-3xl sm:text-4xl font-semibold mt-5 sm:mt-10 text-[#C91D4F]'>Welcome To Cybozom</h1>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-20 sm:text-lg mx-5 sm:mx-10 md:mx-20 mt-5 sm:mt-10 place-items-center'>
          <p className='text-justify'>
            At Cybozom, we offer cutting-edge virtual reality solutions to help businesses showcase their properties, products, and services in innovative ways. Our services include 3D virtual tours, allowing clients to explore spaces interactively from their homes, and website development to create impactful online presences. We also provide Google My Business (GMB) verification to enhance your online visibility and a virtual helpdesk service with a virtual receptionist to assist clients in real-time. Our goal is to deliver high-quality, immersive experiences that engage and satisfy your customers.
            {/* The website provides 3D virtual tour, architecture real-time rendering walkthrough, and 360 product photography services. They have a skilled and experienced team who create customized solutions for their clients. The services offered are aimed at showcasing properties, products, and services in an engaging and immersive way, and the team is dedicated to providing high-quality results and exceptional service to every client. */}
          </p>
          <Suspense fallback={<div>Loading...</div>}>
            <VideoComponent />
          </Suspense>
          {/* <img src={img} alt="" className='rounded-xl w-full h-full'/> */}
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
