import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import chandrakanth from '../../assets/hospital/chandrakanth nethralaya.png';
import chandrakanth_copy from '../../assets/hospital/chandrakanth nethralaya - Copy.png';
import ascent from '../../assets/hospital/ascent.webp';
import ascent_copy from '../../assets/hospital/ascent - Copy.jpg';
import ImageCard from '../../layouts/ImageCard';

const Hospital = () => {
  const [images, setImages] = useState({
    chandrakanthImg: chandrakanth_copy,
    ascentImg: ascent_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages(prevImages => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: chandrakanth, key: 'chandrakanthImg' },
      { src: ascent, key: 'ascentImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const hospitals = [
    { name: "Dr. Chandrakanth Nethralaya", img: images.chandrakanthImg, url: "https://cybozom.in/360/hospital/dr.chandrakanth/" },
    { name: "Ascent ENT Hospital", img: images.ascentImg, url: "https://www.ascenthospital.com/virtual-tour/index.htm" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>HOSPITAL</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {hospitals.map((hospital, index) => (
          <button key={index} onClick={() => navigateToExternalLink(hospital.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={hospital.name} img={hospital.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Hospital;
