import React from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import heroImg from '../../assets/About_img.webp'
import { Link } from 'react-router-dom';


const Hero = () => {

  const openChat = () => {
    window.location.href = "https://wa.me/+918590506573";
  }

  const makeCall = () => {
    window.location.href = "tel:+918590506573";
  };

  return (
    <div className='mt-32 sm:mt-32 '>
      <div>
        <h1 className='text-3xl min-[450px]:text-5xl sm:text-6xl lg:text-7xl font-semibold min-[450px]:font-normal text-center mx-10 min-[400px]:mx-20 md:mx-40 lg:mx-60'>
          Your Partner in <span className='text-[#C91D4F]'>Virtual</span> Reality Solutions
        </h1>
        <div className='m-auto w-fit mt-7 sm:mt-14 flex gap-1 sm:gap-3'>
          <div className='bg-[#C91D4F] hover:bg-transparent border hover:text-[#C91D4F] hover:border-[#C91D4F] transition duration-200 text-white py-2 sm:py-3 px-3 sm:px-5 flex gap-2 items-center rounded-xl cursor-pointer' onClick={makeCall}><FaPhoneAlt /> Get in Touch</div>
          <div className='bg-black hover:bg-transparent border hover:text-black hover:border-black transition duration-200 text-white py-2 sm:py-3 px-3 sm:px-5 flex gap-2 items-center rounded-xl cursor-pointer' onClick={openChat}><IoLogoWhatsapp /> Chat with us</div>
        </div>
      </div>
      <div className='mx-5 sm:mx-10 md:mx-20 lg:mx-32 m-auto mt-10 sm:mt-20 '>
        <img src={heroImg} alt="" className='w-[80%] md:w-[70%] m-auto'/>
      </div>
      <Link to='/portfolio'>
        <div className='mt-3 w-fit m-auto bg-gradient-to-r from-[#C91D4F] to-gray-700 hover:scale-110 transition duration-500 text-white py-2 sm:py-3 px-5 sm:px-10 flex gap-2 items-center rounded-xl cursor-pointer'>
          Explore Virtual Tour
        </div>
      </Link>
    </div>
  )
}

export default Hero;
