import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import klaan_fitness from '../../assets/streetview/klaan fitness.png';
import klaan_fitness_copy from '../../assets/streetview/klaan fitness - Copy.png';
import gokulam_public from '../../assets/streetview/gokulam public.png';
import gokulam_public_copy from '../../assets/streetview/gokulam public - Copy.png';
import ferryland from '../../assets/streetview/ferryland.png';
import ferryland_copy from '../../assets/streetview/ferryland - Copy.png';
import ImageCard from '../../layouts/ImageCard';

const GoogleStreetView = () => {
  const [images, setImages] = useState({
    klaanImg: klaan_fitness_copy,
    gokulamImg: gokulam_public_copy,
    ferrylandImg: ferryland_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages(prevImages => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: klaan_fitness, key: 'klaanImg' },
      { src: gokulam_public, key: 'gokulamImg' },
      { src: ferryland, key: 'ferrylandImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const streetViews = [
    { name: "Klaan Fitness", img: images.klaanImg, url: "https://www.google.com/maps/@11.2453339,75.8366771,3a,75y,125.6h,90.06t/data=!3m8!1e1!3m6!1sAF1QipMhag3kk99D3dX8QRiE7IWhKSAsl-XH9uF_cBbD!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMhag3kk99D3dX8QRiE7IWhKSAsl-XH9uF_cBbD%3Dw900-h600-k-no-pi-0.060000000000002274-ya93.97324905395507-ro0-fo90!7i8000!8i4000?coh=205410&entry=ttu" },
    { name: "Gokulam Public School CLT", img: images.gokulamImg, url: "https://www.google.com/maps/@11.242741,75.8068028,3a,75y,170.24h,91.53t/data=!3m8!1e1!3m6!1sAF1QipOCEId-CKMZj2n3g_t80HyxHVwXh5U-VTltSYp4!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOCEId-CKMZj2n3g_t80HyxHVwXh5U-VTltSYp4%3Dw900-h600-k-no-pi-1.5300000000000011-ya349.0829565429688-ro0-fo90!7i6000!8i3000?coh=205410&entry=ttu" },
    { name: "Ferryland Karuvanthiruthy", img: images.ferrylandImg, url: "https://www.google.com/maps/@11.1614093,75.8165901,3a,75y,1.41h,89.2t/data=!3m8!1e1!3m6!1sAF1QipMsPcVVfTmNH-LHy2NUA7x3ImwpO6vKpIPOekHp!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMsPcVVfTmNH-LHy2NUA7x3ImwpO6vKpIPOekHp%3Dw900-h600-k-no-pi0.7999999999999972-ya0.6063029503822326-ro0-fo90!7i8000!8i4000?hl=en&coh=205410&entry=ttu" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>GOOGLE STREET VIEW</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {streetViews.map((view, index) => (
          <button key={index} onClick={() => navigateToExternalLink(view.url)} className="focus:outline-none">
            <LazyLoad height={200} offset={100} once>
              <ImageCard name={view.name} img={view.img} />
            </LazyLoad>
          </button>
        ))}
      </div>
    </div>
  );
};

export default GoogleStreetView;
