import React, { useEffect, useState } from 'react';
import valencia_galleria from '../../assets/auditorium/valencia galleria.png';
import valencia_galleria_copy from '../../assets/auditorium/valencia galleria - Copy.png';
import rhythm_galleria from '../../assets/auditorium/rhythm.jpg';
import rhythm_galleria_copy from '../../assets/auditorium/rhythm - Copy.jpg';
import ImageCard from '../../layouts/ImageCard';

const Auditorium = () => {
  const [images, setImages] = useState({
    valenciaGalleriaImg: valencia_galleria_copy,
    rhythmGalleriaImg: rhythm_galleria_copy,
  });

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  useEffect(() => {
    const loadImage = (src, key) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImages(prevImages => ({ ...prevImages, [key]: src }));
      };
    };

    const imageSources = [
      { src: valencia_galleria, key: 'valenciaGalleriaImg' },
      { src: rhythm_galleria, key: 'rhythmGalleriaImg' },
    ];

    imageSources.forEach(({ src, key }) => loadImage(src, key));
  }, []);

  const auditoriums = [
    { name: "Valencia Galleria", img: images.valenciaGalleriaImg, url: "https://cybozom.in/public/360/valenciagalleria/" },
    { name: "Rhythm Galleria", img: images.rhythmGalleriaImg, url: "https://www.cybozom.site/360/rhythm4/" },
  ];

  const navigateToExternalLink = (url) => {
    window.location.href = url;
  };

  return (
    <div className='mt-24'>
      <h1 className='text-[1.5rem] min-[425px]:text-[2rem] md:text-[3rem] tracking-widest text-[#293461] text-center'>AUDITORIUM</h1>
      <hr className='w-full border border-[#293461]' />
      <div className='mt-10 grid grid-cols-1 min-[300px]:grid-cols-2 md:grid-cols-3 gap-5 sm:gap-10 mx-5 md:mx-10'>
        {auditoriums.map((auditorium, index) => (
          <button key={index} onClick={() => navigateToExternalLink(auditorium.url)} className="focus:outline-none">
            <ImageCard name={auditorium.name} img={auditorium.img} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Auditorium;
