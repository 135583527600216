import React from 'react'
import './ContactForm.css'
import Swal from 'sweetalert2'

const ContactForm = () => {

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "78d83def-b7d9-4480-b0da-caffc4fef034");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: 'Success!',
        text: 'Message sent successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      })
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit} className='m-auto w-[85%] sm:w-[75%] md:w-[50%] p-10'>
        <input type="text" name='name' placeholder='Name' required/><br />
        <input type="email" name='email' placeholder='Email' required/><br />
        <input type="text" name='subject' placeholder='Subject' required/><br />
        <textarea type="text" name='message' placeholder='Message' required/><br />
        <div className='w-fit m-auto'><button className='mt-5 bg-black text-white px-3 sm:px-5 py-1 sm:py-2 rounded-xl'>Submit</button></div>
      </form>
    </div>
  )
}

export default ContactForm
