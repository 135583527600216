import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import About from './pages/About/About';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Portfolio from './pages/Portfolio/Portfolio';
import Hotel from './pages/Portfolio/Hotel';
import School from './pages/Portfolio/School';
import College from './pages/Portfolio/College';
import Resort from './pages/Portfolio/Resort';
import Hospital from './pages/Portfolio/Hospital';
import Gym from './pages/Portfolio/Gym';
import Auditorium from './pages/Portfolio/Auditorium';
import Coworking from './pages/Portfolio/Coworking';
import VirtualHelpdesk from './pages/Portfolio/VirtualHelpdesk';
import VirtualTour from './pages/Portfolio/VirtualTour';
import GoogleStreetView from './pages/Portfolio/GoogleStreetView';
import VirtualExpo from './pages/Portfolio/VirtualExpo';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />} >
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/school" element={<School />} />
          <Route path="/college" element={<College />} />
          <Route path="/resort" element={<Resort />} />
          <Route path="/hospital" element={<Hospital />} />
          <Route path="/gym" element={<Gym />} />
          <Route path="/auditorium" element={<Auditorium />} />
          <Route path="/coworking" element={<Coworking />} />
          <Route path="/virtual-helpdesk" element={<VirtualHelpdesk />} />
          <Route path="/virtual-tour" element={<VirtualTour />} />
          <Route path="/google-streetview" element={<GoogleStreetView />} />
          <Route path="/virtual-expo" element={<VirtualExpo />} />
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
