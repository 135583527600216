import React from 'react'
import { FaFacebook, FaLinkedin, FaPinterest } from 'react-icons/fa'
import { FaSquareInstagram } from 'react-icons/fa6'
import { IoLogoWhatsapp } from 'react-icons/io'
import { Link } from 'react-router-dom'

const Footer = () => {

    const openChat = () => {
        window.location.href = "https://wa.me/+918590506573"
    }

  return (
    <div className='bg-[#C91D4F] text-white '>
    <div className='p-10 sm:p-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 justify-evenly mt-16 sm:mt-32'>
        <div>
            <h1 className='font-bold text-4xl -tracking-widest'>Cybozom</h1>
            <p className='text-sm ms-1 italic tracking-tighter'>The Virtual Partner</p>
            <p className='font-semibold mt-5'>We are best in industry for your succes story.</p>
            <div className='flex gap-3 mt-7 sm:mt-10'>
                <Link onClick={openChat}><IoLogoWhatsapp className='w-7 h-7 hover:scale-110'/></Link>
                <Link to='https://www.facebook.com/cybozom360?mibextid=ZbWKwL'><FaFacebook className='h-7 w-7 hover:scale-110'/></Link>
                <Link to='https://instagram.com/cybozom360?igshid=YmMyMTA2M2Y'><FaSquareInstagram className='h-7 w-7 hover:scale-110'/></Link>
                <Link to='https://www.linkedin.com/in/cybozom/'><FaLinkedin className='w-7 h-7 hover:scale-110'/></Link>
                {/* <Link to='https://x.com/'><FaSquareXTwitter className='w-7 h-7 hover:scale-110'/></Link> */}
                <Link to='https://in.pinterest.com/cybozom/'><FaPinterest className='w-7 h-7 hover:scale-110'/></Link>
            </div>
        </div>
        <div className='sm:mx-auto mt-5 sm:mt-0'>
            <h1 className='text-lg font-semibold'>Quick Links</h1>
            <ul className='list-none mt-5 text-sm '>
                <Link className='hover:text-gray-300 text-white' to='/'><li className='mt-2'>Home</li></Link>
                <Link className='hover:text-gray-300 text-white' to='/portfolio'><li className='mt-2'>Portfolio</li></Link>
                <Link className='hover:text-gray-300 text-white' to='/about'><li className='mt-2'>About</li></Link>
                <Link className='hover:text-gray-300 text-white' to='/contact'><li className='mt-2'>Contact</li></Link>
            </ul>
        </div>
        <div className='mt-5 md:mt-0 text-white'>
            <h1 className='text-lg font-semibold mb-5'>Contact Us</h1>
            <p className='mb-2'>Phone: +91 8590 506 573</p>
            <p className='mb-2'>Email: cybozom2.0@gmail.com</p>
            <p>Room 1302, 3rd floor, Hilite Business Park, Kozhikode Hilite City, National Highway 66 Bypass, Thondayad, Pantheeramkavu, Kozhikode, Kerala 673014</p>
        </div> 
    </div>
        <p className='text-center pb-1'>© 2024 Cybozom. All rights reserved.</p>     
    </div>
  )
}

export default Footer
