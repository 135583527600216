import React from 'react'

const ImageCard = (props) => {
  return (
    <div className='flex flex-col justify-between h-full rounded-lg p-1 min-[450px]:p-2 shadow  bg-white  transition duration-200 group hover:scale-[1.05]'>
      <img src={props.img} alt="" className='w-[100%] object-cover  border-black group-hover:opacity-100 h-32 min-[450px]:h-40 md:h-60  m-auto rounded-lg '/>
      <div className='flex-grow flex items-center justify-center'>
        <h3 className='text-sm sm:text-lg scale-105 text-[#293461] mt-3 sm:mt-4 mb-2 text-center'>{props.name}</h3>
      </div>
    </div>
  )
}

export default ImageCard
