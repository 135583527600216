import React, { useEffect } from 'react';
import img from '../../assets/header (1).webp'
import commitment from '../../assets/commitment.webp'
import mission from '../../assets/mission.webp'
import vision from '../../assets/vision.webp'
import AboutCard from '../../layouts/AboutCard';
import { TbCircleDotFilled } from "react-icons/tb";
import Aos from 'aos';

const About = () => {

  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className=''>
      <div className='w-fit m-auto mt-28 sm:mt-40'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20 sm:text-lg mx-5 sm:mx-10 md:mx-20 mt-5 sm:mt-10 place-items-center'>
          <div>
            <h1 className='text-xl min-[450px]:text-3xl font-semibold mb-5 text-[#C91D4F]'>Welcome to Cybozom</h1>
            <p className='text-justify'>
              At Cybozom, we offer cutting-edge virtual reality solutions to help businesses showcase their properties, products, and services in innovative ways. Our services include 3D virtual tours, allowing clients to explore spaces interactively from their homes, and website development to create impactful online presences. We also provide Google My Business (GMB) verification to enhance your online visibility and a virtual helpdesk service with a virtual receptionist to assist clients in real-time. Our goal is to deliver high-quality, immersive experiences that engage and satisfy your customers.
            </p>
          </div>
          <img src={img} alt="" className='rounded-xl'/>
        </div>
        
        <h1 className='text-center text-xl font-semibold mt-32'>Our Core Values</h1>
        <hr className='border-[#C91D4F] w-10 border-2 m-auto mt-1 rounded-full'/>
        <div className='mx-5 min-[500px]:mx-20 sm:mx-5 lg:mx-20 mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 lg:gap-10 justify-center'>
            <AboutCard img={commitment} title="Our Commitment" text="At Cybozom, we are dedicated to delivering high-quality results and exceptional service to every client. Our skilled and experienced team works closely with you to create customized solutions that meet your specific needs and objectives. We pride ourselves on our ability to combine technology and creativity to produce immersive experiences that leave a lasting impression."/>
            <AboutCard img={mission} title="Our Mission" text="Our mission is to empower businesses with innovative virtual reality solutions that enhance customer engagement and optimize service delivery. We aim to bridge the gap between technology and business needs by providing services that are not only technologically advanced but also user-friendly and highly effective."/>
            <AboutCard img={vision} title="Our Vision" text="Our vision is to become the leading provider of virtual reality solutions, helping businesses of all sizes to harness the power of immersive technology. By continually innovating and pushing the boundaries of what is possible, we strive to create new opportunities for our clients and set new standards in the industry."/>
        </div>

        <div className='mx-5 sm:mx-10 md:mx-20 mt-10 flex gap-2 items-baseline text-[#C91D4F]'>
          <div className='w-10'>
            <TbCircleDotFilled className='pt-1 w-5 h-5'/>
          </div>
          <p className='  font-semibold'>Thank you for choosing Cybozom as your partner in virtual reality solutions. We look forward to working with you to bring your vision to life.</p>
        </div>

      </div>
    </div>
  );
}

export default About;
