import React from 'react'
import { GoArrowRight } from "react-icons/go";

const CategoriesCard = (props) => {
  return (
    <div className='bg-[#FCEAE8] hover:bg-[#C91D4F] hover:scale-[1.05] hover:text-white transition duration-200 rounded-xl p-2 min-[335px]:p-3 md:p-5 py-5 md:py-10 flex gap-5  justify-between'>
      <div className='flex gap-5 md:gap-10 items-center'>
        <p className='font-bold text-lg text-gray-400'>{props.num}</p>
        <h1 className='text-lg min-[395px]:text-xl md:text-2xl font-semibold'>{props.title}</h1>
      </div>
      <div>
        <GoArrowRight className='w-10 h-10'/>
      </div>
      {/* <p>{props.text}</p> */}
    </div>
  )
}

export default CategoriesCard
