import React, { useState, useEffect } from 'react';
import ServiceCard from '../../layouts/ServiceCard/ServiceCard';
import vr_tour from '../../assets/virtual-tour.png';
import web_dev from '../../assets/webDev-icon.png';
import vr_helpdesk_white from '../../assets/vr-helpdesk-w.png';
import vr_helpdesk_pink from '../../assets/vr-helpdesk-p.png';
import google_business_white from '../../assets/google-my-business-w.png';
import google_business_pink from '../../assets/google-my-business-p.png';
import Aos from 'aos';

const Services = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className='mt-10 sm:mt-20 '>
      <div className='w-fit m-auto '>
        <h1  className='text-center text-xl font-semibold'>Our Services</h1>
        <hr  className='border-[#C91D4F] w-10 border-2 m-auto mt-1 rounded-full'/>
        <h1  className='text-center text-2xl min-[450px]:text-3xl sm:text-4xl font-semibold mt-5 sm:mt-10 text-[#C91D4F]'>Explore Popular Services</h1>
      </div>
      <div className='mx-5 sm:mx-10 md:mx-20 mt-10 sm:mt-16 grid grid-cols-1 sm:grid-cols-2 gap-10'>
        <div data-aos="fade-up"  className='bg-[#C91D4F] rounded-xl text-white shadow'>
          <ServiceCard name="360° Virtual Tour" img={vr_tour} text="You can show your audience your company's features and benefits in 360° view with a voiceover to explain them. You can access it through a web link from your desktop, mobile device, or VR headset." />
        </div>
        <div data-aos="fade-up" className='shadow rounded-xl'>
        <ServiceCard name="Website Development" img={web_dev} text="Create your ideal website with our expert development services. We design and build customized sites that elevate your online presence and engage your audience effectively. Partner with us to bring your digital presence to life." />
        </div>
        <div data-aos="fade-up" className={`rounded-xl shadow ${windowWidth < 640 ? 'bg-[#C91D4F] text-white' : 'bg-transparent text-black'}`}>
          <ServiceCard name="GMB Verification" img={windowWidth < 640 ? google_business_white : google_business_pink} text="Streamline your GMB verification process with our expert service, ensuring your business appears credible on Google Search and Maps. Gain a verified badge to enhance customer trust and improve local SEO rankings, attracting more clients to your business." />
        </div>
        <div data-aos="fade-up" className={`rounded-xl shadow ${windowWidth < 640 ? 'bg-none text-black' : 'bg-[#C91D4F] text-white'}`}>
          <ServiceCard name="Virtual Helpdesk" img={windowWidth < 640 ? vr_helpdesk_pink : vr_helpdesk_white} text="Enhance your customer engagement with our Virtual Helpdesk. We upload your promotional content and provide a virtual receptionist to inform visitors about your company. Clients can easily make inquiries and receive information through this interactive, virtual service." />
        </div>
      </div>
    </div>
  );
};

export default Services;
